<template>
  <section class="d-flex flex-column justify-center align-center mt-1">
    <h1 class="text-center text-gold">{{ $t('My Profile') }}</h1>
    <article class="d-flex flex-wrap justify-space-around mt-3">
      <v-card class="m-auto shadow" width="400px" :class="[`${$store.getters['app/app'].color.name}-gradient`]">
        <v-card-text>
          <v-form v-model="form.valid" @keyup.enter.native="submit">
            <v-text-field v-model="form.name.value"                   :rules="form.name.rules"                :label="$t('Name')"                 color='secondary' type="text"></v-text-field>
            <v-text-field v-model="form.email.value"                                                           label="Email"                      color='secondary' type="text" disabled></v-text-field>
            <div class="my-5">
              <h3 class="text-white mb-3">Alterar Senha <span>(opcional)</span></h3>
              <v-text-field v-model="form.currentPassword.value"      :rules="form.currentPassword.rules"     :label="$t('Current password')"     color='secondary' type="password"></v-text-field>
              <v-text-field v-model="form.newPassword.value"          :rules="form.newPassword.rules"         :label="$t('New password')"         color='secondary' type="password"></v-text-field>
              <v-text-field v-model="form.newPasswordConfirmation.value"                                      :label="$t('Confirm new password')" color='secondary' type="password"
                :rules="[ form.newPasswordConfirmation.value === form.newPassword.value || 'As senhas devem ser iguais' ]"
              ></v-text-field>
            </div>
            <v-text-field v-model="form.phone.value"                                                          :label="$t('Phone')"                color='secondary' type="text" v-mask="'(##) ####-#####'"></v-text-field>
            <v-text-field v-model="form.birthday.value"               :rules="form.birthday.rules"            :label="$t('Birthday')"             color='secondary' v-mask="'##/##/####'"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <Button class="my-5" color="secondary" :disabled="!form.valid" :height="40" :width="250" :loading="loading" @click="submit">{{ $t('Save') }}</Button>
        </v-card-actions>
      </v-card>
    </article>
    <span class="erase text-center mt-5" @click="confirmingRemoval = true">{{ $t('Erase Profile') }}</span>
    <span class="back text-center mt-5" @click="$router.back()">{{ $t('Back') }}</span>
    <Dialog v-if="confirmingRemoval" :width="335" :height="185" @close="confirmingRemoval = false">
      <YesNo :message="'Do you confirm the erasion of your profile?'" @result="removalResult($event, user.id)"/>
    </Dialog>
  </section>
</template>
<script>
import { equalObjects } from '@/utils/object'
import { showSnackBar } from '@/services/snackbar'

export default {
  name: 'Profile',
  components: {
    Button: () => import('@/components/ui/Button'),
    Dialog: () => import('@/components/ui/Dialog'),
    YesNo:  () => import('@/components/ui/YesNo'),
  },
  data: () => ({
    user: null,
    form: {
      id: {
        value: ''
      },
      name: {
        value: '',
        rules: [ v => !!v || 'Campo obrigatório' ]
      },
      email: {
        value: '',
      },
      currentPassword: {
        value: '',
        rules: [v => v.length > 5 || !v.length || "A senha deve conter ao menos 6 caracteres"]
      },
      newPassword: {
        value: '',
        rules: [v => v.length > 5 || !v.length || "A senha deve conter ao menos 6 caracteres"]
      },
      newPasswordConfirmation: {
        value: '',
      },
      phone: {
        value: '',
      },
      birthday: {
        value: '',
        rules: [ v => !!v || 'Campo obrigatório' ]
      },
      valid: false
    },
    loading: false,
    confirmingRemoval: false,
    storeWatchers: {
      user: null
    }
  }),
  created () {
    this.user = this.$store.getters['user/user']
    this.patchFormValues()
    this.watchAndSetUser()
  },
  beforeDestroy () {
    this.storeWatchers.user()
  },
  methods: {
    patchFormValues () {
      if (!this.user) 
        return

      this.form.id.value = this.user.id
      this.form.name.value = this.user.name
      this.form.email.value = this.user.email
      this.form.phone.value = this.user.phone
      this.form.birthday.value = this.user.birthday || ''
    },
    async submit () {
      if (!this.form.valid) 
        return

      if (this.form.newPassword.value && 
        !await this.$store.dispatch('auth/changePassword', {
          email: this.form.email.value,
          currentPassword: this.form.currentPassword.value,
          newPassword: this.form.newPassword.value 
        }))
        return
            
      const user = {
        id: this.form.id.value,
        name: this.form.name.value,
        email: this.form.email.value,
        ...(this.form.phone.value && { phone: this.form.phone.value }),
        ...(this.form.birthday.value && { birthday: this.form.birthday.value })
      }

      if (!this.userDataChanged(user))
        return

      this.updateUser(user)
    },
    watchAndSetUser () {
      this.storeWatchers.user = this.$store.watch(
        state => state.user.user,
        changedUser => { 
          this.user = changedUser 
          this.patchFormValues()
        },
        { deep: true }
      )
    },
    userDataChanged (user) {
      if (equalObjects({ ...this.user }, user) && !this.form.newPassword.value) {
        showSnackBar({
          message: `You didn't edit any data of your profile.`,
          config: {
            color: 'orange',
            timeout: 1500,
          }
        })
        return false
      }
      return true
    },
    updateUser (user) {
      this.$store.dispatch('user/updateUser', user)
        .then(() => 
          showSnackBar({
            message: 'Your profile has been successfully updated!',
              config: {
                color: '#13b719',
                timeout: 3500
              }
          })
        )
        .catch(err => console.log(err))
    },
    async removalResult(result, userID) {
      if (result) {
        await this.removeUser(userID)
        this.confirmingRemoval = false  
      }
    },
    async removeUser(userID) {
      await this.$store.dispatch('auth/removeUser')
      await this.$store.dispatch('user/removeUser', userID)      
      this.$router.push('/')
        showSnackBar({
          message: `You profile has been erased. Feel free to come back if you want to!`,
          config: {
            color: '#13b719',
            timeout: 3500,
          }
        })
    }
  },
  
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';

  ::v-deep {
    input [disabled] { color: gray !important; }

    .v-input__slot::before {
      border-image: none !important;
    }

    .error--text {
      color: #ffae00 !important;
      caret-color: #ffae00 !important;
    } 
  }

  section {
    h1, h2 { 
      font-family: 'Pacifico', cursive; 
      text-shadow: 1px 1px 1px gray;
    }

    h1 { font-size: 28px; }

    h3 {
      span {
        font-style: italic;
        font-size: 12px;
        font-weight: normal;
      }
    }

    article {
      width: 100%;
      max-width: 900px;

      & > * {
        margin: 1em 0;
      }

      .v-card__actions {
        button { width: 95% !important; }
      }
    }
  }

  span {
    &.erase, &.back {
      &:hover { 
        cursor: pointer;
        text-decoration: underline; 
      }
    }
  }

</style>