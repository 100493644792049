/* eslint-disable */
export const equalObjects = (a, b) => {
  if (a === b) return true;
    // if both a and b are null or undefined and exactly the same

  if (!(a instanceof Object) || !(b instanceof Object)) return false;
    // if they are not strictly equal, they both need to be Objects

  if (a.constructor !== b.constructor) return false;
    // they must have the exact same prototype chain, the closest we can do is
    // test there constructor.

  for (const prop in a) {
    if (!a.hasOwnProperty(prop)) continue;
      // other properties were tested using a.constructor === b.constructor
      // Bad pratic due to: " Do not access Object.prototype method 'hasOwnProperty' from target object  no-prototype-builtins"

    if (!b.hasOwnProperty(prop)) return false;
      // allows to compare a[prop] and b[prop] when set to undefined
      // Bad pratic due to: " Do not access Object.prototype method 'hasOwnProperty' from target object  no-prototype-builtins"

    if (a[prop] === b[prop]) continue;
      // if they have the same strict value or identity then they are equal

    if (typeof(a[prop]) !== "object") return false;
      // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!equalObjects(a[prop], b[prop])) return false;
      // Objects and Arrays must be tested recursively
  }

  for (const prop in b)
    if (b.hasOwnProperty(prop) && !a.hasOwnProperty(prop)) // Bad pratic due to: " Do not access Object.prototype method 'hasOwnProperty' from target object  no-prototype-builtins"
      return false; // allows a[prop] to be set to undefined

  return true;
}